import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import {
  foodGallery,
  foodGallery2,
  mobileGallery,
  mobileGallery2,
  mobileGallery3,
  mobileGallery4,
} from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mb="20px" mt="-15px" bg="black" column center>
          <CFImage src={mobileGallery} w="100%" alt="Food Gallery" />
          <CFImage src={mobileGallery2} w="100%" alt="Food Gallery" />
          <CFImage src={mobileGallery3} w="100%" alt="Food Gallery" />
          <CFImage src={mobileGallery4} w="100%" alt="Food Gallery" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center maxWidth="1400px" m="0 auto" mt="-5px">
          <CFImage src={foodGallery} w="100%" alt="Food Gallery" />
          <CFImage src={foodGallery2} w="100%" alt="Food Gallery" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
